/**
* Generated automatically at built-time (2025-04-01T07:29:33.221Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "offert-utekok",templateKey: "sites/99-c2de0939-f982-42ba-b445-caaa901b4c1d"};