/**
* Generated automatically at built-time (2025-04-01T07:29:33.203Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "natalie-test",templateKey: "sites/99-8c645ec3-1bf6-437e-a0ad-2b908009fec3"};